<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="box-name-page">
        <p class="title">จัดการข้อมูลผู้ประกอบการ ตามเกณฑ์พิจารณาสินเชื่อ</p>
        <div class="is-flex ai-center">
          <el-input
            id="searchCriteria"
            placeholder="ค้นหาเกณฑ์พิจารณาสินเชื่อ"
            suffix-icon="el-icon-search"
            class="search"
            clearable
            v-model="searchCriteria"
            @input="searchCriteriaName"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div
      v-loading.lock="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      class="box-card mg-t-5"
    >
      <div
        v-if="!loading"
        class="animate__animated animate__fadeIn animate__slow"
      >
        <el-row :gutter="30" v-if="tableCriteria.length > 0">
          <el-col
            :span="24"
            :sm="12"
            :md="8"
            :lg="6"
            v-for="(criteria, indexC) in tableCriteria"
            :key="indexC"
            class="mg-b-5"
          >
            <CardListCriteria :data="criteria" :page="`user`" />
          </el-col>
        </el-row>
        <div class="text-center" v-else>
          <p class="font-30 font-weight-500">ไม่พบข้อมูล</p>
        </div>
      </div>

      <div class="text-center bg-white pd-t-5" v-if="tableCriteria.length > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="changePage"
          :current-page.sync="pageNow"
          :page-sizes="[15, 30, 50, 100, 500]"
          :page-size="15"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        >
        </el-pagination>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import CardListCriteria from "@/components/card/CardListCriteria";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    CardListCriteria,
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "user");
    this.getCriterions();
  },
  data() {
    return {
      totalPage: 0,
      pageNow: 1,
      limit: 16,
      loading: false,
      searchCriteria: "",
      tableCriteria: [],
      debounce: null,
      loadingData: true,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  methods: {
    getCriterions() {
      let obj = {
        page: this.pageNow,
        limit: this.limit,
        key: this.searchCriteria,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/criterion`, obj)
        .then((res) => {
          if (res.data.success) {
            this.tableCriteria = res.data.obj.data;
            this.totalPage = res.data.obj.totalPage;
            this.loading = false;
            this.loadingData = false;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
          if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
        });
    },
    searchCriteriaName() {
      this.loading = true;
      this.pageNow = 1;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getCriterions();
      }, 600);
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.loading = true;
      this.pageNow = page;
      setTimeout(() => {
        this.getCriterions();
      }, 600);
      // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  },
};
</script>
